/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"osvetleni vyrobni hala"}>
        <Column className="pb--30 pt--30" name={"kjrv2flr8p"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Výměna osvětlení"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" content={"V této zakázce proběhly elektroinstalační práce formou kompletní výměny osvětlení výrobní haly. Demontáž stávajícího osvětlení, zaměření, zavěšení žlabů s následnou montáží svítidel a jejich napojení do stávajících rozvaděčů. Práce probíhaly za plného provozu a z vysokozdvižných plošin z důvodu vysokého stropu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--0" name={"ba7sswk9z7c"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3 pt--20" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/39658fe27d8646a3b50806c19d4f211f_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/d9a12dd9ddef4e2e871b1a78a01af092_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/d9a12dd9ddef4e2e871b1a78a01af092_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/d9a12dd9ddef4e2e871b1a78a01af092_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/d9a12dd9ddef4e2e871b1a78a01af092_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/d9a12dd9ddef4e2e871b1a78a01af092_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/6eb112ffad0c4f3b9c6b2e303a14e759_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/6eb112ffad0c4f3b9c6b2e303a14e759_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/6eb112ffad0c4f3b9c6b2e303a14e759_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/6eb112ffad0c4f3b9c6b2e303a14e759_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/6eb112ffad0c4f3b9c6b2e303a14e759_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/05201bd3a18049888926c3ef3da90cbd_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/05201bd3a18049888926c3ef3da90cbd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/05201bd3a18049888926c3ef3da90cbd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/05201bd3a18049888926c3ef3da90cbd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/05201bd3a18049888926c3ef3da90cbd_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/e70feb8c580b49dabcfac26addefaee6_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/e70feb8c580b49dabcfac26addefaee6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/e70feb8c580b49dabcfac26addefaee6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/e70feb8c580b49dabcfac26addefaee6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/e70feb8c580b49dabcfac26addefaee6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/e70feb8c580b49dabcfac26addefaee6_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}